import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isFirstLogin, setIsFirstLogin] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');
    const userInfo = Cookies.get('user');
    console.log('Token from cookie:', token);
    console.log('User info from cookie:', userInfo);
    if (token && userInfo && userInfo !== "undefined") {
      try {
        const parsedUserInfo = JSON.parse(userInfo);
        setIsAuthenticated(true);
        setUser(parsedUserInfo);
        console.log('Parsed User Info:', parsedUserInfo);

        // Check if this is the user's first login ever
        if (!localStorage.getItem('hasLoggedInBefore')) {
          setIsFirstLogin(true);
          localStorage.setItem('hasLoggedInBefore', 'true');
        } else {
          setIsFirstLogin(false);
        }
      } catch (error) {
        console.error('Error parsing user info:', error);
        setIsAuthenticated(false);
        setUser(null);
      }
    } else {
      setIsAuthenticated(false);
      setUser(null);
    }
  }, []);

  const login = (token, userInfo) => {
    console.log('Setting token and user info in cookies');
    Cookies.set('token', token, { expires: 1 }); // Expires in 1 day
    Cookies.set('user', JSON.stringify(userInfo), { expires: 1 });
    setIsAuthenticated(true);
    setUser(userInfo);

    // Check if this is the user's first login ever
    if (!localStorage.getItem('hasLoggedInBefore')) {
      setIsFirstLogin(true);
      localStorage.setItem('hasLoggedInBefore', 'true');
    } else {
      setIsFirstLogin(false);
    }
  };

  const logout = () => {
    console.log('Removing token and user info from cookies');
    Cookies.remove('token');
    Cookies.remove('user');
    setIsAuthenticated(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, setUser, login, logout, isFirstLogin, setIsFirstLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
