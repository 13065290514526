import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import AuthProvider from './components/AuthContext';
import CookieBanner from './components/CookieBanner';
import ScrollToTop from './components/ScrollToTop';
import './App.css';

// Lazy load components
const LandingPage = lazy(() => import('./components/LandingPage'));
const TryOnFeature = lazy(() => import('./components/TryOnFeature'));
const GetTokensPage = lazy(() => import('./components/GetTokensPage'));
const ContactPage = lazy(() => import('./components/ContactPage'));
const TermsOfUse = lazy(() => import('./components/TermsOfUse'));
const LegalDisclosure = lazy(() => import('./components/LegalDisclosure'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const LoginForm = lazy(() => import('./components/LoginForm'));
const SignUp = lazy(() => import('./components/SignUp'));
const AccountPage = lazy(() => import('./components/AccountPage'));
const LearnMorePage = lazy(() => import("./components/LearnMorePage"));
const VerifyEmail = lazy(() => import('./components/VerifyEmail'));
const VerifyEmailNotice = lazy(() => import('./components/VerifyEmailNotice'));
const RequestPasswordReset = lazy(() => import("./components/RequestPasswordReset"));
const ResetPassword = lazy(() => import("./components/ResetPassword"));
const ComingSoonPage = lazy(() => import('./components/ComingSoonPage'));
const PrivacyPancat = lazy(() => import('./components/PrivacyPancat'));
const LandingPage_Pancat =lazy(() => import('./components/LandingPage_Pancat'));

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <CookieBanner /> {/* Include the CookieBanner component */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<LandingPage_Pancat />} />
            <Route path="/tryon" element={<TryOnFeature />} />
            <Route path="/get-tokens" element={<GetTokensPage />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/legaldisclosure" element={<LegalDisclosure />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path='/learn-more' element={<LearnMorePage />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/verify-email-notice" element={<VerifyEmailNotice />} />
            <Route path="/request-password-reset" element={<RequestPasswordReset />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/privacypancat" element={<PrivacyPancat />} />
          </Routes>
        </Suspense>
      </Router>
    </AuthProvider>
  );
};

export default App;
