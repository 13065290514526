import React, { useContext, useEffect, useState } from 'react';
import { Typography, IconButton, Box, Drawer, List, ListItem, ListItemText, Divider, Avatar } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import tenToken from '../assets/tenToken.webp';
import logo from "../assets/logo.webp";
import "./xcss/luxuryStyles.css";  // CSS-Datei einbinden

const Header = () => {
  const { isAuthenticated, user, logout } = useContext(AuthContext);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const address = process.env.REACT_APP_BACKEND_ADDRESS
  const fport = process.env.REACT_APP_FLASK_PORT

  const fetchTokenBalance = async () => {
    if (user) {
      try {
        const response = await axios.get(`https://${address}:${fport}/api/user-tokens/${user.id}`);
        setTokenBalance(response.data.tokens);
      } catch (error) {
        console.error('Error fetching token balance:', error);
      }
    }
  };

  useEffect(() => {
    fetchTokenBalance();
  }, [user]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerContent = (
    <Box onClick={handleDrawerToggle} sx={{ width: 250, backgroundColor: '#1c1c1c', color: '#e0e0e0', height: '100%' }}>
      <Box sx={{ padding: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Avatar src={logo} alt="TryOnAI Logo" component={Link} to="/" sx={{ width: 56, height: 56, marginRight: 2 }} />
        <Typography component={Link} to="/" variant="h6" className="luxury-heading">
          TryOnAI
        </Typography>
      </Box>
      <Divider sx={{ backgroundColor: '#e0e0e0' }} />
      <List>
        <ListItem button component={Link} to="/tryon">
          <ListItemText primary="Virtual Try-On" className="luxury-subheading" />
        </ListItem>
        <ListItem button component={Link} to="/get-tokens">
          <ListItemText primary="Get Tokens" className="luxury-subheading" />
        </ListItem>
        {isAuthenticated ? (
          <>
            <ListItem button component={Link} to="/account">
              <ListItemText primary="Account" className="luxury-subheading" />
            </ListItem>
            <ListItem button onClick={logout}>
              <ListItemText primary="Logout" className="luxury-subheading" />
            </ListItem>
            <ListItem>
              <div className="token-balance">
                <img src={tenToken} alt="Token Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                <Typography variant="body2" className="luxury-subheading">
                  {tokenBalance}
                </Typography>
              </div>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem button component={Link} to="/signup">
              <ListItemText primary="Sign Up" className="luxury-subheading" />
            </ListItem>
            <ListItem button component={Link} to="/login">
              <ListItemText primary="Login" className="luxury-subheading" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <header className="luxury-app-bar">
      <div className="luxury-toolbar">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/">
            <img src={logo} alt="TryOnAI Logo" style={{ height: '40px', marginRight: '16px' }} />
          </Link>
          <Typography variant="h6" component={Link} to="/" className="luxury-heading">
            TryOnAI
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          {isMobile ? (
            <>
              <IconButton onClick={handleDrawerToggle} className="nav-link">
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle} sx={{ '& .MuiDrawer-paper': { backgroundColor: '#1c1c1c', color: '#e0e0e0' } }}>
                {drawerContent}
              </Drawer>
            </>
          ) : (
            <>
              <Link to="/tryon" className="nav-link">
                Virtual Try-On
              </Link>
              <Link to="/get-tokens" className="nav-link">
                Get Tokens
              </Link>
              {isAuthenticated ? (
                <>
                  <div className="token-balance">
                    <img src={tenToken} alt="Token Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
                    <Typography variant="body2" className="luxury-subheading">
                      {tokenBalance}
                    </Typography>
                  </div>
                  <IconButton component={Link} to="/account" className="nav-link">
                    <AccountCircleIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Link to="/signup" className="nav-link">
                    Sign Up
                  </Link>
                  <Link to="/login" className="nav-link">
                    Login
                  </Link>
                </>
              )}
            </>
          )}
        </Box>
      </div>
    </header>
  );
};

export default Header;
